<template>
  <v-container id="pt-payments-history" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      sort-by="date"
      :sort-desc="true"
      :no-data-text="str['payments_empty']"
      :no-results-text="str['payments_empty']"
      class="elevation-1"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
        </v-toolbar>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    return {
      str: window.strings,
      user: Utils.getUser(),
      data: [],
      search: '',
      headers: [
        { text: window.strings['date'], value: 'date', align: 'center' },
        { text: window.strings['clients'], value: 'clients', align: 'center' },
        { text: window.strings['value_€'], value: 'amount', align: 'center' },
      ],
    }
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getPayments()
  },
  methods: {
    getPayments: function () {
      const self = this
      Api.getPtPayments(function (response) {
        self.$isLoading(false)
        if (response.success) {
          const data = []
          response.data.forEach(function (item, index) {
            item.id = index
            item.date = item.year + '/' + (item.month < 10 ? '0' + item.month : item.month)
            data.push(item)
          })
          self.data = data
        } else {
          self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
        }
      })
    },
  },
}
</script>
